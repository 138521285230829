import { useIntersectionObserver } from "app/hooks/useIntersectionObserver";
import PropTypes from "prop-types";
import { createContext, useContext } from "react";

const AnimationObserverState = createContext();

/**
 * Returns the context containing the
 * IntersectionObserver for animations.
 * @return {React.Context}
 */
export function useAnimationObserver() {
  const context = useContext(AnimationObserverState);
  if (context === undefined) {
    throw new Error(
      "useAnimationObserver must be used within a AnimationObserverProvider"
    );
  }
  return context;
}

export const AnimationObserverProvider = ({ children }) => {
  const [observe, unobserve, observer] = useIntersectionObserver(
    (entry) => {
      const animationCss = entry.getAttribute("data-animation");
      if (animationCss) {
        entry.classList.add(...animationCss.trim().split(" "));
        entry.classList.remove("opacity-0");
      }
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    }
  );

  return (
    <AnimationObserverState.Provider value={{ observe, unobserve, observer }}>
      {children}
    </AnimationObserverState.Provider>
  );
};

AnimationObserverProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
