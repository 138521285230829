import { useState, useEffect, useCallback } from "react";

/**
 * A hook wrapper for IntersectionObserver.
 * @param {Function} onIntersect A callback function that triggers when the
 *                              IntersectionObserver detects a subscribed
 *                              element.
 * @param {Object} options The configurations for the IntersectionObserver.
 *                         Refer to: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
 * @return {Array} Contains the observer instance and callbacks:
 *                 [observe, unobserve, observer]
 */
export function useIntersectionObserver(onIntersect, options) {
  const [observer, setObserver] = useState(null);

  const observe = useCallback(
    (element) => {
      if (observer) {
        observer.observe(element);
      }
    },
    [observer]
  );

  const unobserve = useCallback(
    (element) => {
      if (observer) {
        observer.unobserve(element);
      }
    },
    [observer]
  );

  useEffect(() => {
    setObserver(
      new IntersectionObserver((entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            onIntersect(entry.target);
          }
        }
      }, options)
    );
  }, [setObserver]);

  return [observe, unobserve, observer];
}
